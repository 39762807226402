import { PageBackground, ScreenProtector, Container, NavBar, LogoImg, Logo, ContentContainer } from "../Styling/NavBar-styling";
import IconNavigation from "./IconNavigation";
import HamburgerMenu from "./HamburgerMenu";
import { useNavigate } from "react-router-dom";

const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";

export const StandardBase = ({ children }: { children: React.ReactNode }) => {

  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/user');
  }

  return (
    <PageBackground>
      <ScreenProtector>
        <Container>
          <NavBar>
            <LogoImg onClick={handleHome} src={logoLink} alt="Image"/>
            <Logo onClick={handleHome} >ragge.ai</Logo>
            <HamburgerMenu />
          </NavBar>
          <IconNavigation />
          <ContentContainer>
            {children}
          </ContentContainer>
        </Container>
      </ScreenProtector>
    </PageBackground>
  );
};