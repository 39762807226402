import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Feedback } from "../Types";

export const useFeedbackList = (source_type: string, search_text: string) => {
  return useQuery<Feedback[], Error>({
    queryKey: ["feedback", "all", "list"],
    queryFn: (): Promise<Feedback[]> => ax.get(`/feedback/all/list/?search_text=${search_text}&source_type=${source_type}`).then(res => res.data),
    refetchOnReconnect: "always",
    refetchInterval: 1000 * 30, //hur ofta behöver feedbacklistan uppdateras?
  });
};