import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { api_base_url, ax } from '../Utils';


// Custom hook to manage the assistant chat state
export const useAssistantChat = (module: string, selectedObjId: string | undefined) => {
  const queryClient = useQueryClient();
  const assistantEventSourceRef = useRef<EventSource | null>(null);
  const apiPrefix = module === 'external_chatbot' ? 'external_chatbot' : 'assistant';

  // useQuery to manage assistantChat as a list of strings
  
  const { data: assistantChat } = useQuery<string[]>({
    queryKey: ['assistant_chat', { module }, { obj_id: selectedObjId }],
    queryFn: () => Promise.resolve(['']), // No initial fetch, set initial data as an empty array
    enabled: false, // Disabled fetching
    initialData: [''], // Sets the initial state to an empty string array
  });

  const { data: assistantChatId } = useQuery<string | null>({
    queryKey: ['assistant_chat_id', { module }, { obj_id: selectedObjId }],
    queryFn: () => Promise.resolve(null),
    enabled: false,
    initialData: null,
  });

  // Function to start streaming the answer from the backend
  const streamAnswer = (queryId: string, ): Promise<boolean> => {
    if (assistantEventSourceRef.current) {
      assistantEventSourceRef.current.close();
    }

    return new Promise((resolve) => {
      let contentBuffer = '';

      assistantEventSourceRef.current = new EventSource(
        `${api_base_url}/${apiPrefix}/get_answer?query_id=${queryId}`,
        { withCredentials: true }
      );

      assistantEventSourceRef.current.onmessage = function (event) {
        const data = JSON.parse(event.data);

        console.log(data);

        // Handle the assistant text response
        if (data.copilot_text) {
          queryClient.setQueryData<string[]>(['assistant_chat', { module }, { obj_id: selectedObjId }], (prevChat = ['']) => {
            const lastChatItem = prevChat[prevChat.length - 1];
            const updatedLastChatItem = lastChatItem ? lastChatItem + data.copilot_text : data.copilot_text;
            return [...prevChat.slice(0, -1), updatedLastChatItem];
          });
        }

        // Handle the content text response
        if (data.content_text) {
          if (module === 'internal_chatbot' || module === 'external_chatbot') {
            queryClient.setQueryData<string[]>(['assistant_chat', { module }, { obj_id: selectedObjId }], (prevChat = ['']) => {
              const lastChatItem = prevChat[prevChat.length - 1];
              const updatedLastChatItem = lastChatItem ? lastChatItem + data.content_text : data.content_text;
              return [...prevChat.slice(0, -1), updatedLastChatItem];
            });
          }
          else {
            console.log('content buffer: ', contentBuffer);
            if (!contentBuffer) {
              contentBuffer += data.content_text;
              queryClient.setQueryData<string>(['content', { module }, { obj_id: selectedObjId }], contentBuffer);
            }
            else {
              contentBuffer += data.content_text;
              queryClient.setQueryData<string>(['content', { module }, { obj_id: selectedObjId }], contentBuffer);
            }
          }
        }
      };

      assistantEventSourceRef.current.onerror = (event) => {
        console.log('error:', event);
        assistantEventSourceRef.current?.close();
        handleError();
        resolve(contentBuffer ? true : false);
      };
    });
  };

  const handleError = () => {
    // Handle error scenarios, update state, and close EventSource
    assistantEventSourceRef.current?.close();
    queryClient.setQueryData<boolean[]>(['answer_loading', { module }, { obj_id: selectedObjId }], (prevLoading = []) => {
      const newArray = prevLoading.slice(0, -1);
      newArray.push(false);
      return newArray;
    });
  };

  // Function to handle the answer generation mutation
  const generateAnswer = async (variables: any) => {
    queryClient.setQueryData<boolean[]>(['answer_loading', { module }, { obj_id: selectedObjId }], (prevLoading = []) => {
      return [...prevLoading, true];
    });
    try {
      const requestBody = {
        ...variables,
        assistant_chat_id: assistantChatId,
      };
      const getAnswerId = await ax.post(`/${apiPrefix}/request_params`, requestBody);
      const gotContent = await streamAnswer(getAnswerId.data);
      return gotContent;
    }
    catch (error) {
      handleError();
    }
  };

  // Function to stream the welcome message
  const streamWelcomeMessage = (selectedObj: any) => {
    // Set answerLoading to true when streaming starts
    queryClient.setQueryData<boolean[]>(['answer_loading', { module }, { obj_id: selectedObjId }], [true]);

    if (assistantEventSourceRef.current) {
      assistantEventSourceRef.current.close();
    }

    const url = selectedObj
      ? `${api_base_url}/${apiPrefix}/get_welcome_message?module=${module}&module_ref_id=${selectedObj.id}`
      : `${api_base_url}/${apiPrefix}/get_welcome_message?module=${module}`;

    assistantEventSourceRef.current = new EventSource(url, { withCredentials: true });

    assistantEventSourceRef.current.onmessage = function (event) {
      const data = JSON.parse(event.data);

      // Handle the chat ID response
      if (data.chat_id) {
        console.log('data.chat_id: ', data.chat_id);
        queryClient.setQueryData<string | null>(['assistant_chat_id', { module }, {obj_id: selectedObjId}], data.chat_id);
      }

      // Handle the text response
      if (data.text) {
        queryClient.setQueryData<string[]>(['assistant_chat', { module }, { obj_id: selectedObjId }], (prevChat = ['']) => {
          const lastChatItem = prevChat[0];
          const updatedLastChatItem = lastChatItem ? lastChatItem + data.text : data.text;
          return [...prevChat.slice(0, -1), updatedLastChatItem];
        });
      }
    };

    assistantEventSourceRef.current.onerror = (event) => {
      assistantEventSourceRef.current?.close();
      queryClient.setQueryData<boolean[]>(['answer_loading', { module }, { obj_id: selectedObjId }], [false]);
    };
  };

  const addMessage = (message: string) => {
    queryClient.setQueryData<string[]>(['assistant_chat', { module }, { obj_id: selectedObjId }], (prevChat = ['']) => {
      return [...prevChat, message, ''];
    });
  };

  const clearChat = () => {
    queryClient.setQueryData<string[]>(['assistant_chat', { module }, { obj_id: selectedObjId }], ['']);
  }


  return { assistantChat, generateAnswer, streamWelcomeMessage, addMessage, clearChat };
};