import React, { useRef } from 'react';
import { useState, useEffect } from "react";
import {
  MainContent,
  CopilotSection,
  ConversationSection,
  CopilotContainer,
  CopilotChatItem,
  ChatProfileImgContainer,
  CopilotProfileImg,
  CopilotChatItemText,
  CopilotInputSection,
  CopilotInput,
  CopilotImgAnimation,
  RefreshCopilotButton,
  SendIconButton,
} from "./UserPage-styling";
import RefreshCopilotPopUp from './RefreshCopilotPopUp';
import MarkdownRenderer from '../Components/MarkdownRenderer';
import { useCurrentUser } from '../Queries/useCurrentUser';
import { useAssistantLogo } from '../Queries/useAssistantLogo';
import { useAssistantChat } from '../Queries/useAssistantChat';
import { useAnswerLoading } from '../Queries/useAnswerLoading';
import { FaPaperPlane } from 'react-icons/fa6';
import { StandardBase } from '../Components/StandardBase';


const companyImageLink = 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/ICA_logo.svg/1200px-ICA_logo.svg.png';


const UserPage = () => {

  const module = 'internal_chatbot';

  const [inputText, setInputText] = useState('');
  const conversationRef = useRef<HTMLDivElement>(null);
  const [showRefreshPopUp, setShowRefreshPopUp] = useState(false);
  const [refreshTriggered, setRefreshTriggered] = useState(false);
  
  const hasStreamedWelcomeMessage = useRef(false);

  const { data: currentUser } = useCurrentUser();
  const { data: assistantLogo, ...assistantLogoQuery } = useAssistantLogo(module);
  const {assistantChat, generateAnswer, streamWelcomeMessage, addMessage, clearChat} = useAssistantChat(module, undefined);
  const { data: answerLoading } = useAnswerLoading(module, undefined);

  useEffect(() => {
    if (assistantChat.length === 1 && assistantChat[0] === '' && !hasStreamedWelcomeMessage.current) {
      hasStreamedWelcomeMessage.current = true;
      streamWelcomeMessage(undefined);
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
    if (assistantChat[0] === "" && refreshTriggered) {
      setRefreshTriggered(false);
      streamWelcomeMessage(undefined);
    }
  }, [assistantChat]);

  useEffect(() => {
    if (inputText === '') {
      const textarea = document.getElementById('copilotInputId');
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight - 30 > 100 ? 100 : textarea.scrollHeight - 30) + 'px';
      }
    }
  }, [inputText]);

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    addMessage(inputText);
    generateAnswer({text: inputText, module_name: module});
    setInputText('');
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const toggleShowRefreshPopUp = () => {
    setShowRefreshPopUp(!showRefreshPopUp);
  };

  const handleRefreshCopilot = () => {
    setRefreshTriggered(true);
    clearChat();
    setShowRefreshPopUp(false);
    setInputText('');
  };

  const scrollToBottom = () => {
    if (conversationRef.current && assistantChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StandardBase>
      <MainContent>
        <RefreshCopilotButton onClick={toggleShowRefreshPopUp}>&#10227;</RefreshCopilotButton>
        <CopilotContainer>
          <CopilotSection>
            <ConversationSection ref={conversationRef}>
              {assistantChat.map((item, index) => {
                if (index % 2 === 0) { // Check if index is even
                  return (
                    <CopilotChatItem key={index} $isCopilot={true} >
                      {answerLoading[index/2] &&
                        <CopilotImgAnimation></CopilotImgAnimation>
                      }
                        <ChatProfileImgContainer>
                          {assistantLogo ? (
                            <CopilotProfileImg src={assistantLogo} alt="AI"/>
                          ) : (
                            "AI"
                          )}
                        </ChatProfileImgContainer>
                        {answerLoading[index/2] &&
                          <MarkdownRenderer markdownText={item} isStreaming={true} fontSize={18} />
                        }
                        {!answerLoading[index/2] &&
                          <MarkdownRenderer markdownText={item} isStreaming={false} fontSize={18} />
                        }
                    </CopilotChatItem>
                  )
                } else {
                  return (
                    <CopilotChatItem key={index} $isCopilot={false} >
                      <ChatProfileImgContainer>
                        {currentUser?.first_name.charAt(0)}
                      </ChatProfileImgContainer>
                      <CopilotChatItemText>{item}</CopilotChatItemText>
                    </CopilotChatItem>
                  )
                }
              })}

            </ConversationSection>
            <CopilotInputSection>
              <SendIconButton onClick={handleSend}>
                <FaPaperPlane />
              </SendIconButton>
              <CopilotInput
                id='copilotInputId'
                value={inputText}
                onChange={handleTextareaChange}
                placeholder="Skriv din fråga här..."
                rows={1}
                onKeyDown={handleEnterSend}
              />
            </CopilotInputSection>

          </CopilotSection>
        </CopilotContainer>
      </MainContent>
      {showRefreshPopUp &&
        <RefreshCopilotPopUp
          toggleShowRefreshPopUp={toggleShowRefreshPopUp}
          handleRefreshCopilot={handleRefreshCopilot}
        />
      }
    </StandardBase>
  );
};

export default UserPage;
