


import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { FeedbackType } from "../Types";

export const useFeedbackTypeFilter = () => {
  const queryClient = useQueryClient();

  const {data: feedbackTypeFilter} = useQuery<FeedbackType | null, Error>({
    queryKey: ["feedback", "type", "filter"],
    queryFn: () => Promise.resolve(null),
    enabled: false,
    initialData: null,
  });

  const updateFeedbackTypeFilter = (feedbackTypeFilter: FeedbackType | null) => {
    queryClient.setQueryData<FeedbackType | null>(["feedback", "type", "filter"], feedbackTypeFilter);
  };

  return { feedbackTypeFilter, updateFeedbackTypeFilter };
};