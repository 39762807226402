import React, { useState } from "react";
import AppLayout from "./MainPage";
import LoginPage from "./LoginPage/LoginPage";
import AboutPage from "./AboutPage/AboutPage";
import ContactPage from "./ContactPage/ContactPage";
import UserPage from "./UserPage/UserPage";
import AdminPage from "./AdminPage/AdminPage";
import TemplatesPage from "./TemplatesPage/TemplatesPage";
import ErrandsPage from "./ErrandsPage/ErrandsPage";
import { SearchPage } from "./SearchPage/SearchPage";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CataloguePage from "./CataloguePage/CataloguePage";
import { User } from "./Types";
import StatsPage from "./StatsPage/StatsPage";
//import LandingPage from "./LandingPage/LandingPage";
import MarketingPage from "./MarketingPage/MarketingPage";
import FeedbackPage from "./FeedbackPage/FeedbackPage";
import { MantineProvider } from "@mantine/core";
//import { Notifications } from "@mantine/notifications";
// import '@mantine/core/styles/global.css';
// import '@mantine/notifications/styles.css';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignupPage from "./SignupPage/SignupPage";
import ForgotPasswordPage from "./AuthorizationPages/ForgotPasswordPage";
import ResetPasswordPage from "./AuthorizationPages/ResetPasswordPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import VerificationPage from "./AuthorizationPages/VerificationPage";
import NewEmailInbox from "./EmailInboxPage/EmailInboxPage";
import ExternalChatbot from "./ExternalChatbot/ExternalChatbot";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import ZoidExternalChatbot from "./ZoidExternalChatbot/ZoidExternalChatbot";
import ExternalChatbotTestPage from "./ZoidExternalChatbot/ExternalChatbotTestPage";



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      gcTime: 1000 * 60 * 60 * 24,
    },
  },
});

// Set up a persister using local storage
const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

// Persist the query client with local storage
persistQueryClient({
  queryClient,
  persister: localStoragePersister,
  maxAge: 1000 * 60 * 60 * 24, // Optional: data older than this will not be restored
});

const App = () => {

  //queryClient.prefetchQuery({queryKey: ['users', 'me'], queryFn: getCurrentUser});

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider>
        <ToastContainer 
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Zoom}
        />
        <Router>
          <div>
            <Routes>
              <Route path="/login" 
                element={<LoginPage />}
              />
              <Route path="/errandsold" element={<AppLayout />} />
              {/* <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} /> */}
              <Route path="/user" element={<UserPage />} />
              <Route path="/stats" element={<StatsPage />} />
              <Route path="/admin" 
                element={<AdminPage />}
              />
              <Route path="/admin/templates" element={<TemplatesPage />} />
              <Route path="/admin/email/inbox/new" element={<NewEmailInbox />} />
              <Route path="/errands" element={<ErrandsPage />} />
              <Route path="/feedback" element={<FeedbackPage />} />
              <Route path="/marketing" element={<MarketingPage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/catalogue" element={<CataloguePage />} />
              <Route path="/external-chatbot-test" element={<ExternalChatbotTestPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/login/forgot" element={<ForgotPasswordPage />} />
              <Route path="/login/reset/email/:email/token/:token" element={<ResetPasswordPage />} />
              <Route path="/login/verify/:token" element={<VerificationPage />} />

              <Route path="/chatbot" element={<ZoidExternalChatbot />} />

              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="*" element={<NotFoundPage />} />

            </Routes>
          </div>
        </Router>
      </MantineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
