import React, { useCallback } from 'react';
import { useState, useEffect, useRef } from "react";
import { ax, api_base_url } from "../Utils";
import { Email, Feedback, FeedbackType, Message, User } from '../Types';
import FeedbackList from './Sections/FeedbackList';
import FeedbackCopilot from './Sections/FeedbackCopilot';
import FeedbackChat from './Sections/FeedbackChat';
import { add, debounce, set, update } from 'lodash';
import { Id, toast } from 'react-toastify';
import { Editor } from '@tiptap/react';
import { useSelectedFeedback } from '../Queries/useSelectedFeedback';
import { useAssistantChat } from '../Queries/useAssistantChat';
import { useAnswerLoading } from '../Queries/useAnswerLoading';
import { useContentFeedback } from '../Queries/useContentFeedback';
import { useQueryClient } from '@tanstack/react-query';
import { useEmailDraftMutation } from '../Mutations/EmailDraftMutation';
import { useFeedbackContext } from '../Queries/useFeedbackContext';
import { StandardBase } from '../Components/StandardBase';


const FeedbackPage = () => {
  console.log("FeedbackPage render");
  const queryClient = useQueryClient();

  const module = 'feedback';

  const timeUnits = [
    { singular: 'år', unit: 'år', value: 1000 * 60 * 60 * 24 * 365 },
    { singular: 'månad', unit: 'månader', value: 1000 * 60 * 60 * 24 * 30 },
    { singular: 'dag', unit: 'dagar', value: 1000 * 60 * 60 * 24 },
    { singular: 'timme', unit: 'timmar', value: 1000 * 60 * 60 },
    { singular: 'minut', unit: 'minuter', value: 1000 * 60 },
    { singular: 'sekund', unit: 'sekunder', value: 1000 }
  ];

  const [inputText, setInputText] = useState('');
  const conversationRef = useRef<HTMLDivElement>(null);

  const [lastClickedId, setLastClickedId] = useState<string | null>(null);

  const toastId = useRef<Id | null>(null);
  const editorRef = useRef<Editor | null>(null);

  const { selectedFeedback, updateSelectedFeedback } = useSelectedFeedback();
  const { assistantChat, generateAnswer, streamWelcomeMessage, addMessage } = useAssistantChat(module, selectedFeedback?.id);
  const { data: answerLoading } = useAnswerLoading(module, selectedFeedback?.id);
  //const feedbackThread = useFeedbackThread(selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);
  const { data: content } = useContentFeedback(module, selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);
  //const { data: draft } = useDraft(selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);
  //const { data: emailAddresses } = useRecipientEmails(selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);
  const { feedbackThread, draftEmail: draft, recipientEmails: emailAddresses } = useFeedbackContext(selectedFeedback?.feedback_source_type_name, selectedFeedback?.id);

  const emailDraftMutation = useEmailDraftMutation();

  const selectedFeedbackRef = useRef(selectedFeedback);


  useEffect(() => {
    scrollToBottom();
  }, [assistantChat]);

  useEffect(() => {
    if (inputText === '') {
      const textarea = document.getElementById('feedbackCopilotInputId');
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight - 30 > 100 ? 100 : textarea.scrollHeight - 30) + 'px';
      }
    }
  }, [inputText]);

  useEffect(() => {
    if (selectedFeedback) {
      setLastClickedId(selectedFeedback.id);
      updatePage(selectedFeedback.id);
    }
    else {
      setLastClickedId(null);
      blankPage();
    }
    setInputText('');
    selectedFeedbackRef.current = selectedFeedback;
  }, [selectedFeedback?.id]);


  const updatePage = async (id: string) => { 
    if ((assistantChat.length === 1 && assistantChat[0] === '') ) {//|| assistantChat.length === 0
      streamWelcomeMessage(selectedFeedback);
    }
  };

  const blankPage = () => {
    if (assistantChat.length === 1 && assistantChat[0] === '') {
      streamWelcomeMessage(selectedFeedback);
    }
  };

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    addMessage(inputText);
    generateAnswer( {text: inputText, module_name: module, current_content: editorRef.current?.getHTML() || '', module_ref_id: selectedFeedback?.id}).then((gotContent) => {
      if (gotContent) {
        handleChange(editorRef.current?.getHTML() || '', editorRef.current?.getText() || '', draft, selectedFeedback);
      }
    });
    setInputText('');
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleTextareaChange = (e: { target: { value: React.SetStateAction<string>; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };

  const scrollToBottom = () => {
    if (conversationRef.current && assistantChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  function timePassed(date: Date): string {
    const now = new Date();
    const difference = now.getTime() - date.getTime();
    let { singular, unit, value } = timeUnits.find(({value}) => difference >= value) || timeUnits[timeUnits.length - 1];
    if (Math.floor(difference / value) === 1) return `${Math.floor(difference / value)} ${singular}`;
    return `${Math.floor(difference / value)} ${unit}`;
  }

  // Debounced function that shows the notification
  const handleChange = useCallback(debounce((html: string, text: string, thenDraft: Email | null, thenFeedback: Feedback | null) => {
    console.log("handleChange", thenFeedback);
    if (thenFeedback) {
      toastId.current = toast.info('Saving draft...');
      console.log("hereios");
      if (thenDraft && thenDraft.id) {
        const requestBody: any = {
          id: thenDraft.id,
          feedback_id: thenDraft.feedback_id,
          body: html,
        }
        console.log('with id: ', requestBody);
        emailDraftMutation.mutate(requestBody, {
          onSuccess: (data) => {
            toast.update(toastId?.current ?? '', { type: 'success', render: 'Draft saved', autoClose: 2000 });
          },
          onError: (error) => {
            toast.update(toastId?.current ?? '', { type: 'error', render: 'Draft could not be saved', autoClose: 2000 });
          }
        });
      }

      else {
        const requestBody: any = {
          feedback_id: thenFeedback.id,
          body: html,
        }
        console.log('without id: ', requestBody);
        emailDraftMutation.mutate(requestBody, {
          onSuccess: (data) => {
            toast.update(toastId?.current ?? '', { type: 'success', render: 'Draft saved', autoClose: 2000 });
          },
          onError: (error) => {
            toast.update(toastId?.current ?? '', { type: 'error', render: 'Draft could not be saved', autoClose: 2000 });
          }
        });
      }
    }
  }, 4000), []); // 4 seconds

  const sendEmail = async (html: string) => {
    if (html && selectedFeedback && draft && emailAddresses) {
      handleChange.cancel();
      const requestBody = {
        id: draft.id,
        feedback_id: selectedFeedback.id,
        body: html,
        to_attendees: emailAddresses.to.map((email: string) => ({ identifier: email, display_name: email })),
        cc_attendees: emailAddresses.cc.map((email: string) => ({ identifier: email, display_name: email })),
        bcc_attendees: emailAddresses.bcc.map((email: string) => ({ identifier: email, display_name: email })),
      };
      ax.post(`/email/send/`, requestBody)
        .then(async (response) => {
          toast.success('Email sent!');
          queryClient.setQueryData([{feedback_type: selectedFeedback.feedback_source_type_name}, 'feedback', { feedback_id: selectedFeedback.id }],
            (oldData: Email[]) => {
              const sentEmail = oldData.find((email: Email) => email.id === draft.id);
              if (sentEmail) {
                sentEmail.sent_at = response.data;
                return oldData.filter((email: Email) => email.id !== sentEmail.id).concat(sentEmail);
              }
              return oldData;
            }
          );
          queryClient.setQueryData(['content', { module }, { obj_id: selectedFeedback.id }], '');
          queryClient.invalidateQueries({ queryKey: [{feedback_type: selectedFeedback.feedback_source_type_name}, 'feedback', { feedback_id: selectedFeedback.id }] });
          queryClient.invalidateQueries({ queryKey: ['content', { module }, { obj_id: selectedFeedback.id }] });
        });
    }
    else {
      toast.error('Error: Could not send email.');
    }
  };

  
  return (
    <StandardBase>
      <FeedbackList
        lastClickedId={lastClickedId}
      />
      <FeedbackChat 
        feedbackThread={feedbackThread}
        timePassed={timePassed}
        selectedFeedback={selectedFeedback}
        handleChange={handleChange}
        cancelHandleChange={handleChange.cancel}
        content={content}
        selectedFeedbackRef={selectedFeedbackRef}
        sendEmail={sendEmail}
        editorRef={editorRef}
        draft={draft}
        emailAddresses={emailAddresses}
      />
      <FeedbackCopilot
        inputText={inputText}
        handleTextareaChange={handleTextareaChange}
        handleSend={handleSend}
        handleEnterSend={handleEnterSend}
        conversationRef={conversationRef}
        copilotChat={assistantChat}
        answerLoading={answerLoading}
      />
    </StandardBase>
  );
};
export default FeedbackPage;
