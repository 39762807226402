import React, { useEffect, useRef } from 'react';
import { useState } from "react";
import { ax, formatDate } from "../Utils";
import { Template, User, FAQ, Feedback, Message, Marketing, SearchResult } from '../Types';
import {
  DisplaySection,
  FilterSection,
  ResultsSection,
  SearchBar,
  SearchBarContainer,
  Header,
  FilterContainer,
  FilterText,
  FilterBox,
  ResultsListContainer,
  ResultItemStyling,
  ResultItemText,
  ResultItemTitle,
  FavouriteStar,
  ResultTitleSection,
  FilterTime,
  DisplayChatText,
  ChatItemText,
  SearchIconButton,
} from './CataloguePage-styling';
import {
  AddMessageButton,
  AddMessageContainer,
  Checkbox,
  ErrandCardDate,
  ErrandCardTitle,
  MessageBubble,
  MessageContainer,
  MessageTitle,
  MessageTitleContainer,
  MessagesArea,
} from '../ErrandsPage/ErrandsPage-styling';
import { useCurrentUser } from '../Queries/useCurrentUser';
import { useOrganizationUsers } from '../Queries/useOrganizationUsers';
import { FaSearch } from 'react-icons/fa';
import { StandardBase } from '../Components/StandardBase';
import { useTextTypes } from '../Queries/useTextTypes';



const logoLink = "https://pngimg.com/uploads/letter_c/letter_c_PNG97.png";
const searchGlassLink = "https://i.postimg.cc/q78Sb9zk/wepik-export-20230921105747-Oz8-E.png";
const favouriteStarLink = "https://i.postimg.cc/jSwwmKvd/wepik-export-20230929162319z-Qb2.png"
const yellowFavouriteStarLink = "https://i.postimg.cc/ncJ2kC49/wepik-export-20231005150920fc-Rt.png";

export type Search = {
  text: string;
};

type ConversationProps = {
  chat: Message[];
};

const Conversation = (props: ConversationProps) => {
  return (
    <>
      {props.chat.map((item, index) => {
        if (item.role === 'customer') {
          return(
            <MessageContainer key={index}>
              <MessageBubble
                style={{'backgroundColor': 'rgba(0, 0, 0, 0.3)', 'marginRight': '15%', 'maxWidth': '85%'} as React.CSSProperties}
                $isCustomer={true} >
                <DisplayChatText>{item.text}</DisplayChatText>
                <ErrandCardDate>
                  {formatDate(item.last_updated_at)}
                </ErrandCardDate>
              </MessageBubble>
            </MessageContainer>
          );
        }
        else if (item.text){
          console.log("item", item);
          return(
            <MessageContainer key={index}>
              <MessageBubble
                style={{'backgroundColor': 'rgba(15, 134, 161, 0.7)', 'marginLeft': '15%', 'maxWidth': '85%'} as React.CSSProperties}
                $isCustomer={false}>
                <DisplayChatText>{item.text}</DisplayChatText>
                <ErrandCardDate>
                  {formatDate(item.last_updated_at)}
                </ErrandCardDate>
              </MessageBubble>
            </MessageContainer>
          );
        }
      })}
    </>
  );
}



const CataloguePage = () => {
  const [searchText, setSearchText] = useState("");
  const [favouritesOnly, setFavouritesOnly] = useState(false);
  const [searchResult, setSearchResult] = useState<SearchResult[]>([]);
  const [lastClickedItem, setLastClickedItem] = useState<SearchResult | null>(null);
  const [chat, setChat] = useState<Message[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState<Feedback | null>(null);//TODO: ta bort alla
  // const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);// specifika selected och
  // const [selectedFaq, setSelectedFaq] = useState<FAQ | null>(null);               // använd bara lastClickedItem
  // const [selectedMarketing, setSelectedMarketing] = useState<Marketing | null>(null); // använd bara lastClickedItem
  const [users, setUsers] = useState<User[]>([]);
  const [selectedType, setSelectedType] = useState('');
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [noResults, setNoResults] = useState(false);
  const [userSelectEnabled, setUserSelectEnabled] = useState(false);
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');


  const { data: currentUser} = useCurrentUser();
  const { data: organizationUsers} = useOrganizationUsers(currentUser?.org_id);
  const { data: textTypes} = useTextTypes();

  useEffect(() => {
    const currentDate = new Date();
    const toYear = currentDate.getFullYear();
    const fromYear = currentDate.getFullYear() - 5;
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const nowDate = `${toYear}-${month}-${day}`;
    const fiveYearDate = `${fromYear}-${month}-${day}`;
    setToTime(nowDate);
    setFromTime(fiveYearDate);
  }, []);

  // useEffect (() => {
  //   if (searchResult.length === 0) {
  //     setNoResults(true);
  //   }
  //   else {
  //     setNoResults(false)
  //   }
  // }, [searchResult]);

  const toggleFavouritesOnly = () => {
    setFavouritesOnly(!favouritesOnly);
  };

  const getSearchResult = async () => {
    setSearchResult([]);
    console.log('user', selectedUser, 'type', selectedType, 'text', searchText, 'from', fromTime, 'to', toTime/*, 'favourites', favouritesOnly*/);

    const requestBody: any = {
      text: searchText,
      // "only_favorites": favouritesOnly,
    };
  
    if (selectedType) {
      requestBody.types = [selectedType];
      console.log("types", requestBody.types);
    }
    if (selectedUser) {
      requestBody.user_ids = [selectedUser];
    }
    if (selectedType === 'feedback' || selectedUser) {
      requestBody.from_date = `${fromTime}T23:59`;
      requestBody.to_date = `${toTime}T23:59`;
    }
  
    try {
      const response = await ax.post('/search', requestBody);
      setSearchResult(response.data);
      setNoResults(response.data.length === 0);
      console.log(response.data);
    } catch (error) {
      console.error('Search failed:', error);
      setNoResults(true);
    }
  };

  const handleEnterSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      getSearchResult();
    }
  };

  const handleTypeChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    setSelectedType(value);
    setUserSelectEnabled(value === 'feedback');
    if (value !== 'feedback') {
      setSelectedUser(null);
    }
  };

  const handleUserChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === '0') {
      setSelectedUser(null);
    }
    else {
      setSelectedUser(value);
    }
  };

  const handleFromTimeChange = (event: { target: { value: any; }; }) => {
    const timeValue = event.target.value;
    setFromTime(timeValue);
  };

  const handleToTimeChange = (event: { target: { value: any; }; }) => {
    const timeValue = event.target.value;
    setToTime(timeValue);
  };

  const setSelectedItem = async (item: SearchResult) => { //TODO: Ändra så att bara lastClickedItem används och fixa för olika feedback typer
    setLastClickedItem(item);
    setSelectedFeedback(null);
    // setSelectedTemplate(null);
    // setSelectedFaq(null);
    // setSelectedMarketing(null);
    setChat([]);
    if (item.feedback) {
      setSelectedFeedback(item.feedback);
      const chatResponse = await ax.get(`/feedback/chat?feedback_id=${item.feedback.id}`);
      const newChat = chatResponse.data;
      setChat(newChat);
    }
    // else if (item.template) {
    //   setSelectedTemplate(item.template);
    // }
    // else if (item.faq) {
    //   setSelectedFaq(item.faq);
    // }
    // else if (item.marketing) {
    //   setSelectedMarketing(item.marketing);
    // }
  };

  return (
    <StandardBase>
      <FilterSection>
        <Header>Kunskapsbanken</Header>
        <SearchBarContainer>
          <SearchIconButton onClick={getSearchResult}>
            <FaSearch />
          </SearchIconButton>
          <SearchBar
            placeholder='Sök...'
            value={searchText}
            onChange={(x: any) => setSearchText(x.target.value)}
            onKeyDown={handleEnterSearch}
          />
        </SearchBarContainer>
        <FilterContainer>
          <FilterText>Typ:</FilterText>
          <FilterBox onChange={handleTypeChange}>
            <option value=''>Alla</option>
            {textTypes?.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </FilterBox>
        </FilterContainer>
        <FilterContainer>
          {userSelectEnabled &&
            <><FilterText>Användare:</FilterText>
              <FilterBox onChange={handleUserChange}>
                <option key={0} value='0'>Alla</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>{user.first_name}</option>
                ))}
              </FilterBox>
            </>
          }
        </FilterContainer>
        <FilterContainer>
          {userSelectEnabled &&
            <><FilterText>Från:</FilterText>
              <FilterTime
                type='date'
                id='fromTime'
                value={fromTime}
                onChange={handleFromTimeChange}
              />
            </>
          }
        </FilterContainer>
        <FilterContainer>
          {userSelectEnabled &&
            <><FilterText>Till:</FilterText>
              <FilterTime 
                type='date'
                id='toTime'
                value={toTime}
                onChange={handleToTimeChange}
              />
            </>
          }
        </FilterContainer>
        {/* <FilterContainer style={{'justifyContent': 'flex-start'} as React.CSSProperties}>
          <FilterText>Visa bara favoriter?</FilterText>
          <Checkbox
            style={{'minHeight': '25px', 'minWidth': '25px', '--check-size': '20px'} as React.CSSProperties}
            checked={favouritesOnly}
            onChange={(e: { target: { checked: boolean; }; }) =>
              toggleFavouritesOnly()}
          />
        </FilterContainer> */}
        <AddMessageContainer>
          <AddMessageButton onClick={getSearchResult}>Sök</AddMessageButton>
        </AddMessageContainer>
      </FilterSection>
      <ResultsSection>
        <Header>Sökresultat</Header>
        <ResultsListContainer>
          {noResults &&
            <FilterText>
              Din sökning gav inga resultat.
              Du kan justera sökningen till vänster och försöka igen.
            </FilterText>
          }
          {searchResult.map((item, index) => {
            if (item.feedback) {
              return(
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>{item.feedback.title}</ResultItemTitle>
                    {/* <FavouriteStar src={favouriteStarLink}/> */}
                  </ResultTitleSection>
                  <ResultItemText>{item.feedback.summary}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else if (item.template) {
              return(
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>Mall - {item.template.title}</ResultItemTitle>
                    {/* <FavouriteStar src={favouriteStarLink}/> */}
                  </ResultTitleSection>
                  <ResultItemText>{item.template.text}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else if (item.faq) {
              return(
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>FAQ - {item.faq.title}</ResultItemTitle>
                    {/* <FavouriteStar src={favouriteStarLink}/> */}
                  </ResultTitleSection>
                  <ResultItemText>{item.faq.question_text}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else if (item.marketing) {
              return(
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>{item.marketing.title}</ResultItemTitle>
                    {/* <FavouriteStar src={favouriteStarLink}/> */}
                  </ResultTitleSection>
                  <ResultItemText>{item.marketing.text}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else if (item.web) {
              return (
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>{item.title}</ResultItemTitle>
                  </ResultTitleSection>
                  <ResultItemText>{item.web.summary}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else if (item.file) {
              return (
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>{item.file.title}</ResultItemTitle>
                  </ResultTitleSection>
                  <ResultItemText>{item.file.description}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else if (item.product) {
              return (
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>{item.product.name}</ResultItemTitle>
                  </ResultTitleSection>
                  <ResultItemText>{item.product.description}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else if (item.custom_data) {
              return (
                <ResultItemStyling key={index} onClick={() => {
                  setSelectedItem(item);
                }}
                  selected={lastClickedItem === item}
                >
                  <ResultTitleSection>
                    <ResultItemTitle>{item.title}</ResultItemTitle>
                  </ResultTitleSection>
                  <ResultItemText>{item.overview/*Object.values(item.custom_data)[0] as string*/}</ResultItemText>
                </ResultItemStyling>
              )
            }
            else {
              console.log("Icke: ", item);
            }
          })}
        </ResultsListContainer>
      </ResultsSection>
      <DisplaySection>
        {lastClickedItem ? 
          <>
            {selectedFeedback ?
              <>
                <MessageTitleContainer>
                  <MessageTitle>{selectedFeedback.title}</MessageTitle>
                </MessageTitleContainer>
                <MessagesArea>
                  <Conversation chat={chat} />
                </MessagesArea>
              </>
            : lastClickedItem.template ?
              <>
                <MessageTitleContainer>
                  <MessageTitle>Mall {lastClickedItem.template.title}</MessageTitle>
                </MessageTitleContainer>
                <MessageContainer>
                  <ChatItemText>{lastClickedItem.template.text}</ChatItemText>
                </MessageContainer>
              </>
            : lastClickedItem.faq ?
              <>
                <MessageTitleContainer>
                  <MessageTitle>FAQ - {lastClickedItem.faq.title}</MessageTitle>
                </MessageTitleContainer>
                <MessagesArea>
                  <MessageContainer>
                    <MessageBubble
                      style={{'backgroundColor': 'rgba(0, 0, 0, 0.3)', 'marginRight': '15%', 'maxWidth': '85%', 'paddingBottom': '6px'} as React.CSSProperties}
                      $isCustomer={true}>
                        <ErrandCardTitle style={{'fontSize': '18px'} as React.CSSProperties}>Fråga</ErrandCardTitle>
                        <ChatItemText>{lastClickedItem.faq.question_text}</ChatItemText>
                    </MessageBubble>
                  </MessageContainer>
                  <MessageContainer>
                    <MessageBubble
                      style={{'backgroundColor': 'rgba(15, 134, 161, 0.7)', 'marginLeft': '15%', 'maxWidth': '85%', 'paddingBottom': '6px'} as React.CSSProperties}
                      $isCustomer={false} >
                        <ErrandCardTitle style={{'fontSize': '18px'} as React.CSSProperties}>Svar</ErrandCardTitle>
                        <ChatItemText>{lastClickedItem.faq.answer_text}</ChatItemText>
                    </MessageBubble>
                  </MessageContainer>
                </MessagesArea>
              </>
            : lastClickedItem.marketing ?
              <>
                <MessageTitleContainer>
                  <MessageTitle>{lastClickedItem.marketing.title}</MessageTitle>
                </MessageTitleContainer>
                <MessageContainer>
                  <ChatItemText>{lastClickedItem.marketing.text}</ChatItemText>
                </MessageContainer>
              </>
            : lastClickedItem.web ?
              <>
                <MessageTitleContainer>
                  <MessageTitle>{lastClickedItem.title}</MessageTitle>
                </MessageTitleContainer>
                <MessageContainer>

                </MessageContainer>
              </>
            : lastClickedItem.custom_data ?
              <>
                <MessageTitleContainer>
                  <MessageTitle>{lastClickedItem.title}</MessageTitle>
                </MessageTitleContainer>
                <MessageContainer>
                  {Object.entries(lastClickedItem.custom_data.obj).map(([key, value], index) => (
                    value ?
                      <ChatItemText key={index}>{key}: {String(value)}</ChatItemText>
                    : null
                  ))}
                </MessageContainer>
              </>
            : <MessageTitleContainer>
                <MessageTitle>Inget valt</MessageTitle>
              </MessageTitleContainer>
          }
          </>
        : <></>
        }
      </DisplaySection>
    </StandardBase>
  );
};

export default CataloguePage;