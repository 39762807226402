import ZoidChatbotWidget from "./ZoidCodeForClients/ZoidChatbotWidget";
import { LandingHeader, MainContent } from "../LandingPage/LandingPage-styling";
import { ax } from "../Utils";
import { StandardBase } from "../Components/StandardBase";
import { useEffect, useState } from "react";

const obj_id = "873e7553-38ee-48b9-a571-f53a28df2432";

const ExternalChatbotTestPage = () => {

  const [clientKey, setClientKey] = useState("");

  useEffect(() => { //gör useQuery istället
    ax.get(`chatbot_config?obj_id=${obj_id}`)
      .then((res) => {
        setClientKey(res.data.api_key);
      });
  }, []);



  return (
    <StandardBase>
      <MainContent>
        <LandingHeader>Testsida för kundchatboten</LandingHeader>
        <h1 style={{textAlign: 'center', marginTop: '0px', fontWeight: '500'}}>
          Klicka på knappen i det nedre högre hörnet för att öppna chatten och testa kundernas upplevelse!
        </h1>
      </MainContent>
      <ZoidChatbotWidget clientKey={clientKey} /> {/* Ladda conditionally när clientKey är satt (med react-query isPending typ)*/}
    </StandardBase>
  );
};

export default ExternalChatbotTestPage;