import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { FeedbackType, Email } from "../Types";


export const useContentFeedback = (module: string, feedback_type: FeedbackType | undefined, obj_id: string | undefined) => {
 
  return useQuery<string, Error>({
    queryKey: ['content', {module}, { obj_id }],
    queryFn: (): Promise<string> => ax.get(`/${feedback_type}/feedback/?feedback_id=${obj_id}`)
        .then((res) =>{
          if (feedback_type === 'external_chatbot') {
            return '';
          }
          else if (feedback_type === 'email') {
            return res.data.find((message: Email) => message.sent_at === null).body;
          }
          return '';
        }),
    enabled: !!obj_id && !!feedback_type,
    initialData: '',
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

};