import { MainContent } from "../LoginPage/LoginPage-styling";
import { LandingHeader } from "../LandingPage/LandingPage-styling";
import { StandardBase } from "../Components/StandardBase";

const NotFoundPage = () => {

  return (
    <StandardBase>
      <MainContent>
        <LandingHeader>
          Page not found!
        </LandingHeader>
      </MainContent>
    </StandardBase>
  );
};

export default NotFoundPage;