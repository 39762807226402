// import { useFeedbackData } from './useFeedbackData';
// import { FeedbackType, FeedbackThread } from '../Types';
// import { useMemo } from 'react';

// export const useFeedbackThread = (
//   feedback_type: FeedbackType | undefined,
//   feedback_id: string | undefined
// ): FeedbackThread | undefined => {
//   console.log("useFeedbackThread called with: ", feedback_type, feedback_id);
//   const { data: feedbackData, ...feedbackDataQuery } = useFeedbackData(feedback_type, feedback_id);
//   return useMemo(() => {
//     if (!feedback_type) return undefined;
    
//     return {
//       feedback_type,
//       data: feedbackData || [],
//       ...feedbackDataQuery,
//     } as FeedbackThread;
//   }, [feedback_type, feedbackData, ...Object.values(feedbackDataQuery)]);
// };

import { useFeedbackData } from './useFeedbackData';
import { FeedbackType, FeedbackThread } from '../Types';
import { useMemo } from 'react';

export const useFeedbackThread = (
  feedback_type: FeedbackType | undefined,
  feedback_id: string | undefined
): FeedbackThread | undefined => {
  const { data: feedbackData, ...feedbackDataQuery } = useFeedbackData(feedback_type, feedback_id);
  
  // Stable reference for query metadata
  const queryMeta = useMemo(() => ({
    isLoading: feedbackDataQuery.isLoading,
    isError: feedbackDataQuery.isError,
    error: feedbackDataQuery.error,
  }), [feedbackDataQuery.isLoading, feedbackDataQuery.isError, feedbackDataQuery.error]);

  return useMemo(() => {
    if (!feedback_type) return undefined;
    
    return {
      feedback_type,
      data: feedbackData || [],
      ...queryMeta,
    } as FeedbackThread;
  }, [feedback_type, feedbackData, queryMeta]);
};