import React from "react";
import { Link } from "react-router-dom";
import { FaBullhorn, FaRegComments, FaRobot, FaRegChartBar, FaBook, FaSearch, FaUserTie, FaEnvelope } from "react-icons/fa";
import "./IconNavigation-styling.css";
import { FaInstagram, FaRegEnvelope, FaWhatsapp } from "react-icons/fa6";
import { TbMessageChatbot } from "react-icons/tb";
import { LuTestTube2 } from "react-icons/lu";
import { useFeedbackTypeFilter } from "../Queries/useFeedbackTypeFilter";
import { FeedbackType } from "../Types";
import { RiSurveyLine } from "react-icons/ri";
import { VscFeedback } from "react-icons/vsc";

const IconNavigation = () => {
  const { feedbackTypeFilter, updateFeedbackTypeFilter } = useFeedbackTypeFilter();
  const handleClick = (filter: string) => {
    //TODO: mutera query som lagrar sökfilter i feedback
    updateFeedbackTypeFilter(filter as FeedbackType);
  };

  return (
    <div className="vertical-navbar">
      <Link to="/user" className="nav-item">
        <FaRobot className="nav-icon"/>
        <span className="nav-text">Assistent</span>
      </Link>
      <Link to="/feedback" className="nav-item"
        onClick={() => updateFeedbackTypeFilter(null)}
      >
        <FaRegComments className="nav-icon"/>
        <span className="nav-text">Feedback</span>
      </Link>
      <div>
        <Link to="/feedback" className="nav-item nav-item-sub"
          onClick={() => handleClick("email")}
        >
          <FaRegEnvelope className="nav-icon nav-icon-sub"/>
          <span className="nav-text nav-text-sub">E-post</span>
        </Link>
        <Link to="/feedback" className="nav-item nav-item-sub"
          onClick={() => handleClick("external_chatbot")}
        >
          <TbMessageChatbot className="nav-icon nav-icon-sub"/>
          <span className="nav-text nav-text-sub">Kundchatbot</span>
          <span className="new-badge">Nyhet!</span>
        </Link>
        <div className="nav-item nav-item-sub disabled-nav-item" >
          <VscFeedback className="nav-icon nav-icon-sub disabled-icon"/>
          <span className="nav-text nav-text-sub">Recensioner</span>
          <span className="coming-soon-badge">Coming Soon</span>
        </div>
        <div className="nav-item nav-item-sub disabled-nav-item" >
          <RiSurveyLine className="nav-icon nav-icon-sub disabled-icon"/>
          <span className="nav-text nav-text-sub">Formulär</span>
          <span className="coming-soon-badge">Coming Soon</span>
        </div>
        <div className="nav-item nav-item-sub disabled-nav-item" >
          <FaWhatsapp className="nav-icon nav-icon-sub disabled-icon"/>
          <span className="nav-text nav-text-sub">WhatsApp</span>
          <span className="coming-soon-badge">Coming Soon</span>
        </div>
        <div className="nav-item nav-item-sub disabled-nav-item" >
          <FaInstagram className="nav-icon nav-icon-sub disabled-icon"/>
          <span className="nav-text nav-text-sub">Instagram</span>
          <span className="coming-soon-badge">Coming Soon</span>
        </div>
        {/* <Link to="/feedback" className="nav-item nav-item-sub"
          onClick={() => handleClick("review")}
        >
          <VscFeedback className="nav-icon nav-icon-sub"/>
          <span className="nav-text nav-text-sub">Recensioner</span>
          <span className="new-badge">Ny!</span>
        </Link>
        <Link to="/feedback" className="nav-item nav-item-sub"
          onClick={() => handleClick("survey")}
        >
          <RiSurveyLine className="nav-icon nav-icon-sub"/>
          <span className="nav-text nav-text-sub">Formulär</span>
          <span className="new-badge">Ny!</span>
        </Link>
        <Link to="/feedback" className="nav-item nav-item-sub"
          onClick={() => handleClick("whatsapp")}
        >
          <FaWhatsapp className="nav-icon nav-icon-sub"/>
          <span className="nav-text nav-text-sub">WhatsApp</span>
          <span className="new-badge">Ny!</span>
        </Link>
        <Link to="/feedback" className="nav-item nav-item-sub"
          onClick={() => handleClick("instagram")}
        >
          <FaInstagram className="nav-icon nav-icon-sub"/>
          <span className="nav-text nav-text-sub">Instagram</span>
          <span className="new-badge">Ny!</span>
        </Link> */}
      </div>
      <Link to="/marketing" className="nav-item">
        <FaBullhorn className="nav-icon"/>
        <span className="nav-text">Marknadsföring</span>
      </Link>
      <Link to="/catalogue" className="nav-item">
        <FaSearch className="nav-icon"/>
        <span className="nav-text">Kunskapsbanken</span>
      </Link>
      <Link to="/stats" className="nav-item">
        <FaRegChartBar className="nav-icon"/>
        <span className="nav-text">Statistik</span>
      </Link>
      <Link to="/admin" className="nav-item">
        <FaUserTie className="nav-icon"/>
        <span className="nav-text">Admin</span>
      </Link>
      <Link to="/external-chatbot-test" className="nav-item">
        <LuTestTube2 className="nav-icon"/>
        <span className="nav-text">Testa kundchatbot</span>
      </Link>
    </div>
  );
}

export default IconNavigation;